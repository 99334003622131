import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment, useState } from "react";
import { Outlet } from "react-router";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <LayoutBodyWrapper>{children || <Outlet />}</LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
