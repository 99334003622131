import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import LayoutV1 from "layouts/layout-v1/DashboardLayout";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

const Survery = Loadable(lazy(() => import("./pages/survey")));

const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [
    {
      path: "",
      element: <LayoutV1 />,
      children: dashboardRoutes,
    },

    {
      path: "*",
      element: <Error />,
    },
  ];
};
const dashboardRoutes = [
  {
    path: "/",
    element: <Survery />,
  },
];
const naverRoutes = [];
export default routes;
